


























import {defineComponent, PropType, ref} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {VersionTaskState} from '@/tasks/types/VersionTaskState';

type Inputs = {
  configurations: {
    atom1: string | null;
    atom2: string | null;
    atom3: string | null;
    atom4: string | null;
    atom5: string | null;
    atom6: string | null;
    atom7: string | null;
  };
};

export default defineComponent({
  name: 'UofTQuiz3Question2',
  mixins: [DynamicQuestionMixin()],
  props: {
    taskState: {
      type: Object as PropType<VersionTaskState>,
      required: true,
    },
  },
  setup(props) {
    const configurations = ['R', 'S'];
    const inputs = ref<Inputs>({
      configurations: {
        atom1: null,
        atom2: null,
        atom3: null,
        atom4: null,
        atom5: null,
        atom6: null,
        atom7: null,
      },
    });

    return {
      inputs,
      configurations,
    };
  },
  computed: {
    version(): number {
      return this.taskState.getValueByName('version')?.content?.value ?? 1;
    },
    image(): string {
      return `/img/assignments/UofT/CHMB41H/2023/Quiz3/Question2/v${this.version}.png`;
    },
    imgWidth(): number {
      if (this.version === 1) {
        return 191;
      } else if (this.version === 2) {
        return 264;
      } else {
        return 335;
      }
    },
  },
});
